import React from "react";
import "animate.css";
import { Link } from "react-router-dom";
// import pgdrsOnlineStudentForm from "../../assets/pdfFiles/onlineForm/PGDRS/online Student Form.pdf";
// import mpaOnlineStudentForm from "../../assets/pdfFiles/onlineForm/MPA/online Student Form_29d3add9-0460-420a-96e6-fba743b2b7ed.pdf";
// import mpaApplication from "../../assets/pdfFiles/onlineForm/MPA/MPA Application.docx";
// import mpaAdmission from "../../assets/pdfFiles/onlineForm/MPA/MPA ADMISSION FORM.docx";
// import mpaAnnounce from "../../assets/pdfFiles/onlineForm/MPA/Announce for MPA.docx";
// import mmmOnlineStudenForm from "../../assets/pdfFiles/onlineForm/MMM/online Student Form_29d3add9-0460-420a-96e6-fba743b2b7ed.pdf";
// import mmmPhamphlet from "../../assets/pdfFiles/onlineForm/MMM/MMM Pamphlet 2025_1.pdf";
// import mmmApplication from "../../assets/pdfFiles/onlineForm/MMM/MMM applicaiton 2025_91a69718-f312-4edb-aefb-23ca10345863_1.pdf";
// import mbaOnlineStudentForm from "../../assets/pdfFiles/onlineForm/MBA/online Student Form.pdf";
// import mbaApplicationPdf from "../../assets/pdfFiles/onlineForm/MBA/MBA Application Form ( 24th Batch).pdf";
// import mbaApplicationExcel from "../../assets/pdfFiles/onlineForm/MBA/MBA Application Form ( 24th Batch).docx";
// import masApplication from "../../assets/pdfFiles/onlineForm/MAS/1. Application Form of MAS_1 (1).pdf";
// import dipdsOnlineStudentForm from "../../assets/pdfFiles/onlineForm/DipDS/online Student Form_29d3add9-0460-420a-96e6-fba743b2b7ed.pdf";
// import dipdsApplication from "../../assets/pdfFiles/onlineForm/DipDS/DipDS Application Form (2025) -17th Batch.pdf";
// import dbsOnlineStudentForm from "../../assets/pdfFiles/onlineForm/DBS/online Student Form_29d3add9-0460-420a-96e6-fba743b2b7ed.pdf";
// import dbsApplication from "../../assets/pdfFiles/onlineForm/DBS/DBS Application Form 2025_1.pdf";
// import dbsAdvertising from "../../assets/pdfFiles/onlineForm/DBS/advertising dbs 6 Batch_c0df0838-69b3-4751-9830-e4a8729214ff_1.pdf";
// import { BiChevronDown, BiChevronUp } from "react-icons/bi";
// import { FaDownload } from "react-icons/fa6";

const Announcements25 = () => {
  // const [showForm, setShowForm] = useState("PGDRS");
  const announcements = [
    {
      id: 1,
      title:
        "ပြည်ထောင်စုစာရင်းစစ်ချုပ်ရုံး လက်ထောက်စာရင်းစစ်မှူး လျှောက်လွှာခေါ်ယူခြင်း",
    },
    {
      id: 2,
      title:
        "ပြည်ထောင်စုစာရင်းစစ်ချုပ်ရုံး ဒု-စာရင်းစစ်မှူး လျှောက်လွှာခေါ်ယူခြင်း ",
    },
  ];
  // const onlineForms = [
  //   {
  //     heading: "PGDRS",
  //     forms: [{ title: "Online Student Form", route: pgdrsOnlineStudentForm }],
  //   },
  //   {
  //     heading: "MPA",
  //     forms: [
  //       { title: "Online Student Form", route: mpaOnlineStudentForm },
  //       { title: "Application Form", route: mpaApplication },
  //       { title: "Admission Form", route: mpaAdmission },
  //       { title: "Announce for MPA", route: mpaAnnounce },
  //     ],
  //   },
  //   {
  //     heading: "MMM",
  //     forms: [
  //       { title: "Online Student Form", route: mmmOnlineStudenForm },
  //       { title: "MMM Pamphlet", route: mmmPhamphlet },
  //       { title: "MMM Application", route: mmmApplication },
  //     ],
  //   },
  //   {
  //     heading: "MBA",
  //     forms: [
  //       { title: "Online Student Form", route: mbaOnlineStudentForm },
  //       { title: "Application Form (PDF)", route: mbaApplicationPdf },
  //       { title: "Application Form (Word)", route: mbaApplicationExcel },
  //     ],
  //   },
  //   {
  //     heading: "MAS",
  //     forms: [{ title: "Application Form", route: masApplication }],
  //   },
  //   {
  //     heading: "DipDS",
  //     forms: [
  //       { title: "Online Student Form", route: dipdsOnlineStudentForm },
  //       { title: "Application Form", route: dipdsApplication },
  //     ],
  //   },
  //   {
  //     heading: "DBS",
  //     forms: [
  //       { title: "Online Student Form", route: dbsOnlineStudentForm },
  //       { title: "Application Form", route: dbsApplication },
  //       { title: "Advertising Material", route: dbsAdvertising },
  //     ],
  //   },
  // ];

  return (
    <>
      <div className="flex flex-col  mb-10 md:flex-row min-h-[60vh] justify-between md:px-32 mt-5">
        <div className="w-full flex-1 flex flex-col gap-5">
          <h2 className="text-xl font-semibold text-blue-950 text-start mb-5">
            Announcements
          </h2>
          {announcements.map((item, index) => (
            <div
              key={item.id}
              className={`h-24 w-full ${
                index < announcements.length - 1
                  ? "border-b-2 border-dashed"
                  : ""
              }`}>
              <h2>{item.title}</h2>
              <Link to={`/announcements/2025/${item.id}`} className="max-w-32">
                <div className="border-2 rounded-md p-1 px-5 mt-5 w-32 text-center text-white bg-blue-950">
                  See More
                </div>
              </Link>
            </div>
          ))}
        </div>
        {/* <div className="min-w-96  md:ml-20 pl-10 border-l-2 ">
          <h2 className="text-xl  text-blue-950 font-semibold text-start">
            Download Forms
          </h2>
          {onlineForms.map((item, index) => (
            <div key={index}>
              <button
                onClick={() => setShowForm(item.heading)}
                className="text-lg flex justify-between items-center w-full font-medium mb-2 mt-3">
                {item.heading}{" "}
                {showForm === item.heading ? (
                  <BiChevronUp size={20} />
                ) : (
                  <BiChevronDown size={25} />
                )}
              </button>

              {showForm !== item.heading && (
                <div className="h-[1px] w-full mb-5 bg-blue-900 hover:duration-300"></div>
              )}
              {showForm === item.heading && (
                <div>
                  {item.forms.map((form, index) => (
                    <div
                      className="animate__fadeInDown mb-3 duration-500"
                      key={index}>
                      <h5 className="flex w-full justify-between items-center">
                        <span className="mr-2 ">
                          {index + 1}.{form.title}
                        </span>
                        <span className="ml-1 font-medium">
                          <a href={form.route}>
                            <u className="text-blue-600">
                              <FaDownload size={17} />
                            </u>
                          </a>
                        </span>
                      </h5>
                    </div>
                  ))}
                  {showForm === item.heading && (
                    <div className="h-[2px] w-full mb-5 bg-blue-900 hover:duration-300"></div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default Announcements25;
