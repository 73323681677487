import { FaChevronRight } from "react-icons/fa";
import { Flex, Icon, Text, Image } from "../ui";
import { IoChevronDownSharp } from "react-icons/io5";
import { siteLogo } from "../assets";
import { useNavigate } from "react-router-dom";
import { items } from "./Items";
import "./index.css";
export const Redirects = ({
  setMobileMenuOpen,
  setActiveDropDown,
  setActiveSubDropDown,
  activeDropdown,
  activeSubDropdown,
  activeGrandchildrenDropdown,
  setActiveGrandchildrenDropDown,
  // setMobileMenuOpen,
  dropdownRef,
  // setIsSearchInputOpen,
}) => {
  const Navigate = useNavigate();

  const toggleRedirected = () => {
    setActiveDropDown(null);
    setActiveSubDropDown(null);
    setActiveGrandchildrenDropDown(null);
    setMobileMenuOpen(false);
  };
  return (
    <>
      <nav
        ref={dropdownRef}
        // onClick={(e) =>{ e.stopPropagation()}}
        className="w-2/3 min-w-80  h-screen   md:h-auto md:max-h-12  p-5 md:p-0 md:w-full md:pr-10  fixed left-0 flex-col flex-wrap z-50  whitespace-normal justify-start items-start"
        style={{ backgroundColor: "rgba(41, 74, 112, 1)" }}>
        {" "}
        <div className="flex md:hidden flex-row justify-start items-center gap-2 ">
          {" "}
          <Image src={siteLogo} height="50px" alt="site logo" width="auto" />
          <Text
            content="Monywa University of Economics"
            color="rgba(255, 215, 0, 1)"
            size="12px"
            weight="500"
            backgroundColor="transparent"
          />
        </div>
        <Flex
          direction="col"
          justify="start"
          align="start"
          className="max-w-96 md:justify-evenly md:pl-32 md:pr-20 md:max-w-full md:flex-row">
          {items.map((item) => (
            <Flex
              direction="col"
              justify="start"
              align="start"
              className="cursor-pointer  min-w-24 max-w-24"
              key={item.key}>
              <a
                href={item.route ? item.route : null}
                target={item.target && "_blank"}
                rel="noreferrer">
                <button
                  className="flex  flex-row justify-start text-base mt-3 mb-3 w-full items-center"
                  onClick={() => {
                    setActiveDropDown(
                      activeDropdown === item.key ? null : item.key
                    );
                    setActiveSubDropDown(null);
                    setActiveGrandchildrenDropDown(null);
                    item.route && toggleRedirected();
                  }}>
                  <Text
                    size="14px"
                    color="white"
                    content={item.label}
                    backgroundColor="transparent"
                    weight="400"
                    className="text-white whitespace-nowrap overflow-hidden"
                  />
                  {item.children && (
                    <Icon
                      IconComponent={IoChevronDownSharp}
                      size="14px"
                      color="white"
                      className="ml-2"
                    />
                  )}
                </button>
              </a>

              <div className=" flex flex-col  rounded-md">
                {" "}
                {item.children &&
                  item.key === activeDropdown &&
                  item.children.map((dropdown, index) => (
                    <>
                      <Flex
                        direction="col"
                        justify="start"
                        align="start"
                        className={`cursor-pointer   rounded-md min-w-52 max-w-52  ml-2 md:flex-row shadow−[0px 0px 6px 0px]  text-black ${dropdown.key}`}
                        key={dropdown.key}>
                        <button
                          className={`flex flex-row w-full p-2  justify-between ${
                            dropdown.key === "2025" || dropdown.key === "2024"
                              ? "min-w-32 max-w-32"
                              : " min-w-[210px] max-w-[210px]"
                          } items-center bg-white h-8 ${
                            index + 1 === item.children.length && "rounded-b-lg"
                          }`}
                          onClick={() => {
                            setActiveSubDropDown(
                              activeSubDropdown === dropdown.key
                                ? null
                                : dropdown.key
                            );
                            setActiveGrandchildrenDropDown(null);

                            dropdown.route && Navigate(dropdown.route);
                            dropdown.route && toggleRedirected();
                          }}
                          style={{
                            backgroundColor: `${
                              activeSubDropdown === dropdown.key
                                ? "rgba(41, 74, 112, 1)"
                                : "white"
                            }`,
                            boxShadow: `${
                              activeSubDropdown === dropdown.key
                                ? "0px 0px 6px 0px"
                                : "0px 2px 4px 0px rgba(41, 74, 112, 1)"
                            }`,
                          }}>
                          <Text
                            size="14px"
                            color={`${
                              activeSubDropdown === dropdown.key
                                ? "white"
                                : "black"
                            }`}
                            content={dropdown.label}
                            weight="400"
                            backgroundColor="transparent"
                            className="text-white whitespace-nowrap overflow-hidden"
                          />
                          {dropdown.grandChildren && (
                            <>
                              {" "}
                              <Icon
                                IconComponent={IoChevronDownSharp}
                                size="14px"
                                color={`${
                                  activeSubDropdown === dropdown.key
                                    ? "white"
                                    : "black"
                                }`}
                                className="ml-2 md:hidden"
                              />
                              <Icon
                                IconComponent={FaChevronRight}
                                size="14px"
                                color={`${
                                  activeSubDropdown === dropdown.key
                                    ? "white"
                                    : "black"
                                }`}
                                className="ml-2 hidden md:block"
                              />
                            </>
                          )}
                        </button>
                        <div className="flex  flex-col">
                          {dropdown.grandChildren &&
                            dropdown.key === activeSubDropdown &&
                            dropdown.grandChildren.map((subDropDown, index) => (
                              <Flex
                                direction="col"
                                justify="start"
                                align="start"
                                className={`cursor-pointer  rounded-md min-w-52  ml-2 md:flex-row shadow−[0px 0px 6px 0px]   text-black ${subDropDown.key}`}
                                key={subDropDown.key}>
                                <button
                                  className={`${
                                    index + 1 === dropdown.grandChildren.length
                                      ? "md:rounded-b-lg"
                                      : ""
                                  } flex flex-row  p-2 justify-between min-w-[185px] max-w-48 hover:bg-[rgba(41, 74, 116)] hover:text-white items-center  h-8 `}
                                  style={{
                                    backgroundColor: `${
                                      activeGrandchildrenDropdown ===
                                      subDropDown.key
                                        ? "rgba(41, 74, 116)"
                                        : "white"
                                    }`,
                                    boxShadow: `${
                                      activeGrandchildrenDropdown ===
                                      subDropDown.key
                                        ? "0px 0px 6px 0px"
                                        : "0px 2px 4px 0px rgba(41, 74, 112, 1)"
                                    }`,
                                  }}
                                  onClick={() => {
                                    setActiveGrandchildrenDropDown(
                                      activeGrandchildrenDropdown ===
                                        subDropDown.key
                                        ? null
                                        : subDropDown.key
                                    );
                                    subDropDown.route &&
                                      Navigate(subDropDown.route);
                                    subDropDown.route && toggleRedirected();
                                  }}>
                                  <Text
                                    size="14px"
                                    color={`${
                                      activeGrandchildrenDropdown ===
                                      subDropDown.key
                                        ? "white"
                                        : "black"
                                    }`}
                                    content={subDropDown.label}
                                    weight="400"
                                    backgroundColor="transparent"
                                    className="text-white whitespace-nowrap overflow-hidden"
                                  />
                                  {subDropDown.greatGrandChildren && (
                                    <>
                                      {" "}
                                      <Icon
                                        IconComponent={IoChevronDownSharp}
                                        size="14px"
                                        color={`${
                                          subDropDown.key ===
                                          activeGrandchildrenDropdown
                                            ? "white"
                                            : "black"
                                        }`}
                                        className="ml-2 md:hidden"
                                      />
                                      <Icon
                                        IconComponent={FaChevronRight}
                                        size="14px"
                                        color={`${
                                          subDropDown.key ===
                                          activeGrandchildrenDropdown
                                            ? "white"
                                            : "black"
                                        }`}
                                        className="ml-2 hidden md:block"
                                      />
                                    </>
                                  )}
                                </button>

                                <div className="flex flex-col rounded-lg">
                                  {subDropDown.greatGrandChildren &&
                                    subDropDown.key ===
                                      activeGrandchildrenDropdown &&
                                    subDropDown.greatGrandChildren.map(
                                      (grandChildDropdown, index) => (
                                        <a href={grandChildDropdown.route}>
                                          <button
                                            className={`${
                                              index + 1 ===
                                              subDropDown.greatGrandChildren
                                                .length
                                                ? "md:rounded-b-lg"
                                                : ""
                                            } flex flex-row ml-2  pl-2 justify-start w-[250px] items-center bg-white h-8`}
                                            key={grandChildDropdown.key}
                                            onClick={() => {
                                              toggleRedirected();
                                            }}
                                            style={{
                                              boxShadow:
                                                "0px 2px 4px 0px rgba(41, 74, 112, 1)",
                                            }}>
                                            <Text
                                              size="14px"
                                              backgroundColor="transparent"
                                              color="black"
                                              content={grandChildDropdown.label}
                                              weight="400"
                                              className="text-white whitespace-nowrap overflow-hidden"
                                            />
                                          </button>
                                        </a>
                                      )
                                    )}
                                </div>
                              </Flex>
                            ))}
                        </div>
                      </Flex>
                    </>
                  ))}
              </div>
            </Flex>
          ))}
        </Flex>
      </nav>
    </>
  );
};
