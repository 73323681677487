import {
  doctoralDegrees,
  graduateDegrees,
  hRDevProgramme,
  postGraduateDegrees,
  professionalDegree,
} from "../../assets";
// import { Flex, Image } from "../../ui";
import { OfferedDegreeCard } from "../../cards";
function DegreeBody() {
  const degreeData = [
    {
      image: graduateDegrees,
      typeOfDegree: "Graduate Degrees",
      justifyStart: false,
      majors: [
        "Bachelor of Economics majoring in Economics (B.Econ /Eco)",
        "Bachelor Economics majoring in Statistics (BEcon /Stats)",
        "Bachelor of Commerce (B.Com)",
        "Bachelor of Accounting (B.Act)",
        "Bachelor of Business Administration (BBA)",
        "Bachelor Economics (Trade and Logistics)",
      ],
    },
    {
      image: postGraduateDegrees,
      typeOfDegree: "Postgraduate Degrees",
      justifyStart: true,
      majors: [
        "Master of Economics majoring in Economics (M.Econ /Eco)",
        "Master of Economics majoring in Statistics (MEcon /Stats)",
        "Master of Commerce (M.Com)",
        "Master of Accounting (M.Act)",
      ],
    },
    {
      image: doctoralDegrees,
      justifyStart: false,
      typeOfDegree: "Master Degree (Professional Degree Programme)",
      majors: [
        "Master of Business Administration (MBA)",
        "Master of Public Administration (MPA)",
        "Master of Development Studies (MDevS)",
        "Master of Banking and Finance (MBF)",
        "Master of Applied Statistics (MAS)",
        "Master of Marketing Management (MMM)",
      ],
    },
    {
      image: professionalDegree,
      justifyStart: true,
      typeOfDegree: "Doctoral Degree Programme",
      majors: [
        "Ph.D in Commerce PhD (Commerce)",
        "Ph.D in Economics PhD (Economics)",
        "Ph.D in Statistics PhD (Statistics)",
      ],
    },
    {
      image: hRDevProgramme,
      justifyStart: false,
      typeOfDegree: "Human Resource Development (HRD) Programme (Diploma)",
      majors: [
        "Post Graduate Diploma in Research Studies (PGDRS)",
        "Diploma in Development Studies (Dip DS)",
        "Diploma in Business Studies (DBS)",
      ],
    },
  ];
  return (
    <>
      {" "}
      <div className="md:mx-32">
        {degreeData.map((degree, index) => (
          <OfferedDegreeCard
            key={index}
            image={degree.image}
            typeOfDegree={degree.typeOfDegree}
            majors={degree.majors}
            justifyStart={degree.justifyStart}
          />
        ))}
      </div>
    </>
  );
}

export default DegreeBody;
