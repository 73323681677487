export const items = [
  {
    key: "home",
    label: "Home",
    route: "/",
  },
  {
    key: "about",
    label: "About MUE",
    children: [
      { key: "about", label: "About", route: "/about" },
      { key: "contactUs", label: "Contact Us", route: "/contact-us" },
      {
        key: "organization",
        label: "Organization",
        route: "/Organization",
      },
      { key: "masterPlan", label: "Master Plan", route: "/master-plan" },
      { key: "campus", label: "Campus", route: "/campus" },
      { key: "degreeOffered", label: "Degree Offered", route: "/degree" },
    ],
  },
  {
    key: "prospectus",
    label: "Prospectus",
    route: "/prospectus",
  },
  {
    key: "department",
    label: "Department",
    children: [
      {
        key: "academicDepartment",
        label: "Academic Department",
        grandChildren: [
          {
            key: "mainDepartment",
            label: "Main Department",
            greatGrandChildren: [
              {
                key: "commerce",
                label: "Department of Commerce",
                route: "/academic-department/main/commerce",
              },
              {
                key: "economics",
                label: "Department of Economics",
                route: "/academic-department/main/economics",
              },
              {
                key: "statistics",
                label: "Department of Statistics",
                route: "/academic-department/main/statistics",
              },
              {
                key: "managementStudies",
                label: "Department of Management Studies",
                route: "/academic-department/main/management",
              },
              {
                key: "appliedEconomics",
                label: "Department of Applied Economics",
                route: "/academic-department/main/applied-economics",
              },
            ],
          },
          {
            key: "supportingDepartment",
            label: "Supporting Department",
            greatGrandChildren: [
              {
                key: "myanmar",
                label: "Department of Myanmar",
                route: "/academic-department/supporting/myanmar",
              },
              {
                key: "english",
                label: "Department of English",
                route: "/academic-department/supporting/english",
              },
              {
                key: "mathematics",
                label: "Department of Mathematics",
                route: "/academic-department/supporting/mathematics",
              },
              {
                key: "geography",
                label: "Department of Geography",
                route: "/academic-department/supporting/geography",
              },
            ],
          },
        ],
      },
      {
        key: "administrativeDepartment",
        label: "Administrative Department",
        grandChildren: [
          {
            key: "admin",
            label: "Admin Department",
            route: "/administrative-department/admin",
          },
          {
            key: "academicAffairs",
            label: "Academic Affairs",
            route: "/administrative-department/academic-affairs",
          },
          {
            key: "finance",
            label: "Finance Department",
            route: "/administrative-department/finance",
          },
          {
            key: "ict",
            label: "ICT Department",
            route: "/administrative-department/ict",
          },

          {
            key: "engineering",
            label: "Engineering",
            route: "/administrative-department/engineering",
          },
          {
            key: "library",
            label: "Library",
            route: "/administrative-department/library",
          },
        ],
      },
    ],
  },
  {
    key: "iro",
    label: "IRO",
    children: [
      { key: "moumoa", label: "MOU/MOA", route: "/mou-moa" },
      { key: "project", label: "Project", route: "/project" },
    ],
  },
  {
    key: "admission",
    label: "Admission",
    children: [
      {
        key: "academicAdmission",
        label: "Academic Admission",
        grandChildren: [
          {
            key: "bachelorAdmission",
            label: "Bachelor Admission",
            route: "/admission/bachelor",
          },
          {
            key: "masterAdmission",
            label: "Master Admission",
            route: "/admission/master",
          },
        ],
      },
      {     
        key: "onlineAdmission",
        label: "Online Admission",
        grandChildren: [
          {
            key: "dbs",
            label: "DBS",
            greatGrandChildren: [
              {
                key: "dbsOnlineStudentForm",
                label: "⬇️Online Student Form",
                route:
                  "https://drive.google.com/file/d/1qhZTIBXo0qdl1siQ37gD0AcJwtOeQ7f_/view?usp=drive_link",
              },
              {
                key: "dbsApplication",
                label: "⬇️Application Form",
                route:
                  "https://drive.google.com/file/d/1GhQPe2RJfrC67WpWbhnItWsi-OhTUztS/view?usp=drive_link",
              },
              {
                key: "dbsAdvertising",
                label: "⬇️Advertising Material",
                route:
                  "https://drive.google.com/file/d/1Qv1TcVaxwpMTbZkXBzTJ7EO6qDReBJzt/view?usp=drive_link",
              },
            ],
          },
          {
            key: "dipds",
            label: "DipDS",
            greatGrandChildren: [
              {
                key: "dipdsOnlineStudentForm",
                label: "⬇️Online Student Form",
                route:
                  "https://drive.google.com/file/d/133THYT0iiOa5ccvBqZ0MV3nDDkDzcyjC/view?usp=drive_link",
              },
              {
                key: "dipdsApplication",
                label: "⬇️Application Form",
                route:
                  "https://drive.google.com/file/d/1Ld_ba7PUfkwTQq4qlrB-LgL0dMKj9Bzi/view?usp=drive_link",
              },
            ],
          },
          {
            key: "pgdrs",
            label: "PGDRS",
            greatGrandChildren: [
              {
                key: "pgdrsOnlineStudentForm",
                label: "⬇️Online Student Form",
                route:
                  "https://drive.google.com/file/d/1B2k9PHiIhN27HATkDJgaqQiYM-CuNiVQ/view?usp=drive_link",
              },
              {
                key: "pgdrsApplicationForm",
                label: "⬇️Application Form",
                route:
                  "https://docs.google.com/document/d/1Rv0TyfgdyEQsaTMc-Wbmfjev4PPwez-y/edit?usp=drive_link&ouid=101877601334829904024&rtpof=true&sd=true",
              },
            ],
          },
          {
            key: "mmm",
            label: "MMM",
            greatGrandChildren: [
              {
                key: "mmmOnlineStudentForm",
                label: "⬇️Online Student Form",
                route:
                  "https://drive.google.com/file/d/1KptVs4d-eueY9sQtIA776sglNzuQHGL2/view?usp=drive_link",
              },
              {
                key: "mmmPamphlet",
                label: "⬇️MMM Pamphlet",
                route:
                  "https://drive.google.com/file/d/1LAgNAgnhZ1Y_PFcj4VxlQdhLN2IKEnFM/view?usp=drive_link",
              },
              {
                key: "mmmApplication",
                label: "⬇️MMM Application",
                route:
                  "https://drive.google.com/file/d/18igMB2FklhFENYPkRZo_KKwWsgTPI3yq/view?usp=drive_link",
              },
            ],
          },
          {
            key: "mas",
            label: "MAS",
            greatGrandChildren: [
              {
                key: "masOnlineApplicationForm",
                label: "⬇️Application Form",
                route:
                  "https://drive.google.com/file/d/1g_R0X7_7Xq39wm1xeEkqyAsDsUAlMxEx/view?usp=drive_link",
              },
            ],
          },
          {
            key: "mpa",
            label: "MPA",
            greatGrandChildren: [
              {
                key: "mpaOnlineStudentForm",
                label: "⬇️Online Student Form",
                route:
                  "https://drive.google.com/file/d/1_E11zjUYLiAGe7fWzaLL2sfVLD85r5RE/view?usp=drive_link",
              },
              {
                key: "mpaApplicationForm",
                label: "⬇️Letter of Recommendation",
                route:
                  "https://docs.google.com/document/d/1BMQwEGDrwNH-iNvcmbqs1LCaGnGuRtxd/edit?usp=drive_link&ouid=101877601334829904024&rtpof=true&sd=true",
              },
              {
                key: "mpaApplicationForm",
                label: "⬇️Application Form",
                route:
                  "https://docs.google.com/document/d/10rp3rG3VZowALYbnLICaBV8iKsf031q1/edit?usp=drive_link&ouid=101877601334829904024&rtpof=true&sd=true",
              },
              {
                key: "mpaAdmissionForm",
                label: "⬇️Admission Form",
                route:
                  "https://docs.google.com/document/d/1SaymcmZXHv8v9pNq4veecHs4tVG4IAms/edit?usp=drive_link&ouid=101877601334829904024&rtpof=true&sd=true",
              },
              {
                key: "mpaAnnounce",
                label: "⬇️Announce for MPA",
                route:
                  "https://docs.google.com/document/d/1BMr4-FJ5FWUNMu28qiP091slS2_8k5GX/edit?usp=drive_link&ouid=101877601334829904024&rtpof=true&sd=true",
              },
            ],
          },
          {
            key: "mba",
            label: "MBA",
            greatGrandChildren: [
              {
                key: "mbaOnlineStudentForm",
                label: "⬇️Online Student Form",
                route:
                  "https://drive.google.com/file/d/1o0886QQFHej8LkeoAynu1S5Rl_TE-XrJ/view?usp=drive_link",
              },
              {
                key: "mbaApplicationPdf",
                label: "⬇️Application Form (PDF)",
                route:
                  "https://drive.google.com/file/d/1llDuKiVOJVqJ8aWffGZm5j1rdM3EoGp-/view?usp=drive_link",
              },
              {
                key: "mbaApplicationWord",
                label: "⬇️Application Form (Word)",
                route:
                  "https://drive.google.com/file/d/1llDuKiVOJVqJ8aWffGZm5j1rdM3EoGp-/view?usp=drive_link",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    key: "research",
    label: "Research",
    children: [
      { key: "maas", label: "MAAS", route: "/research/maas" },
      { key: "urj", label: "URJ", route: "/research/urj" },
      {
        key: "international",
        label: "International",
        route: "/research/international",
      },
      { key: "phdPlan", label: "Ph.D Plan", route: "/research/ph-d" },
      { key: "master", label: "Master", route: "/research/master" },
    ],
  },
  {
    key: "library",
    label: "Library",
    route: "https://library.mue.edu.mm/",
    target: true,
  },

  {
    key: "activities",
    label: "Activities",
    children: [
      { key: "2024", label: "2024", route: "/activities/2024" },
      { key: "2025", label: "2025", route: "/activities/2025" },
    ],
  },
  {
    key: "announcements",
    label: "Announcements",
    children: [{ key: "2025", label: "2025", route: "/announcements/2025" }],
  },
];
