import React from "react";
import { Flex, Text, Image } from "../ui";
const HeadingCard = ({ title, departmentLogo }) => {
  return (
    <>
      <Flex direction="col" justify="center" align="center" className="mt-2">
        <Image
          src={departmentLogo}
          width="auto"
          height="138px"
          alt="University Logo"
        />
        <Text
          content={title}
          size="24px"
          weight="700"
          className="text-wrap text-center"
        />
        <div
          style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
          className="h-1 w-11  mb-3 hover:w-32 hover:duration-1000"></div>
      </Flex>
    </>
  );
};

export default HeadingCard;
