import React, { useState } from "react";
import CommerceFacultyMembers from "./CommerceFacultyMembers";
import CommerceOurHead from "./CommerceOurHead";
import CommerceDegreeOffered from "./CommerceDegreeOffered";
import CommerceCurriculum from "./CommerceCurriculum";
import CommerceResearchPaper from "./CommerceResearchPaper";
import CommerceActivities from "./CommerceActivities";
import { Flex } from "../../ui";

const CommerceSectionsTemplate = ({
  headMaster,
  groupMembers,
  offeredDegrees,
  hrdProgrammes,
  curriculumsTypes,
  researches,
  news,
}) => {
  const [selectedSection, setSelectedSection] = useState("CommerceOurHead");

  const getNavItemStyle = (section) => {
    return section === selectedSection
      ? "active-selection"
      : "text-blue-950 font-semibold text-base hover:text-blue-500";
  };

  return (
    <>
      <Flex direction="col" align="start" justify="start" className="mt-5 p-3">
        {/* Navigation bar */}
        <Flex justify="start" className="max-h-[110px] mb-10">
          <ul
            className="flex flex-row gap-10 flex-nowrap whitespace-nowrap overflow-x-auto w-full h-full"
            style={{
              // borderTop: "1px dashed gray",
              borderBottom: "2px solid",
            }}>
            <li
              onClick={() => setSelectedSection("CommerceOurHead")}
              className={getNavItemStyle("CommerceOurHead")}
              style={{
                fontSize: "16px",
                fontWeight: "600",
                cursor: "pointer",
                letterSpacing: "0.48px",
              }}>
              Our Head
            </li>{" "}
            <li
              onClick={() => setSelectedSection("CommerceFacultyMembers")}
              className={getNavItemStyle("CommerceFacultyMembers")}
              style={{
                fontSize: "16px",
                fontWeight: "600",
                letterSpacing: "0.48px",
                cursor: "pointer",
              }}>
              Our Faculty Members
            </li>
            <li
              onClick={() => setSelectedSection("CommerceDegreeOffered")}
              className={getNavItemStyle("CommerceDegreeOffered")}
              style={{
                fontSize: "16px",
                fontWeight: "600",
                cursor: "pointer",
                letterSpacing: "0.48px",
              }}>
              Degree Offered
            </li>
            <li
              onClick={() => setSelectedSection("CommerceCurriculum")}
              className={getNavItemStyle("CommerceCurriculum")}
              style={{
                fontSize: "16px",
                fontWeight: "600",
                cursor: "pointer",
                letterSpacing: "0.48px",
              }}>
              Curriculum
            </li>
            <li
              onClick={() => setSelectedSection("CommerceResearchPaper")}
              className={getNavItemStyle("CommerceResearchPaper")}
              style={{
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: "600",
                letterSpacing: "0.48px",
              }}>
              Research Papers
            </li>
            <li
              onClick={() => setSelectedSection("CommerceActivities")}
              className={getNavItemStyle("CommerceActivities")}
              style={{
                fontSize: "16px",
                fontWeight: "600",
                letterSpacing: "0.48px",
                cursor: "pointer",
              }}>
              Activities
            </li>
          </ul>
        </Flex>

        {/* Conditional rendering of components */}
        <Flex
          direction="col"
          justify="start"
          align="start"
          className="max-w-[1809px] min-h-auto gap-0">
          {selectedSection === "CommerceFacultyMembers" && (
            <CommerceFacultyMembers facultyDataGroups={groupMembers} />
          )}
          {selectedSection === "CommerceOurHead" && (
            <CommerceOurHead headInfo={headMaster} />
          )}
          {selectedSection === "CommerceDegreeOffered" && (
            <CommerceDegreeOffered
              degrees={offeredDegrees}
              programmes={hrdProgrammes}
            />
          )}
          {selectedSection === "CommerceCurriculum" && (
            <CommerceCurriculum curriculums={curriculumsTypes} />
          )}
          {selectedSection === "CommerceResearchPaper" && (
            <CommerceResearchPaper facultyResearch={researches} />
          )}
          {selectedSection === "CommerceActivities" && (
            <CommerceActivities activities={news} />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default CommerceSectionsTemplate;
