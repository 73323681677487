import React from "react";
import { EconomicsHeading } from "../components";
import CommerceSectionsTemplate from "../components/commerce/CommerceSectionsTemplate";
import {
  economicsActiFootball,
  economicsActiFootball2,
  economicsActiVolleyball,
  economicsActiWorship,
  economicsActiWorship2,
  sandar,
  uKoMyat,
  thazinHtwe,
  eiEiZaw,
  yawZawLain,
  nyuntLwin,
  htetYeeMonOo,
  phyoThinzar,
  eieiWin,
  uKhantNyeinOo,
  saiPhyonaing,
} from "../assets";

const curriculums = [
  {
    title: "Bachelor of Economics (Trade and Logistics)",
    degrees: [
      "Introductory Microeconomics",
      "Introductory Macroeconomics",
      "Money and Banking",
      "Quantitative Analysis",
      "Operations Management",
      "Mathematics for Economics",
      "English for Logistics",
      "International Economic Law",
      "International Trade",
      "Trade Statistics and Economic Indicators",
      "Organizational Management",
      "Geography of Myanmar",
      "English for Logistics",
      "Law of International Trade",
      "Professional English for Students of Logistics",
      "Trade Policy and Procedure in Myanmar",
      "Applied Econometrics",
      "Marketing Management",
      "Introduction to Logistics Management",
      "Public Finance",
      "Organizational Behavior",
      "English for Trade Fairs and Events",
      "Investment Policy and Procedure in Myanmar",
      "Applied Time Series Analysis",
      "Research Methodology",
      "Supply Chain Management",
      "Introduction to International Relations",
      "International Business Management",
      "Trade and Development",
      "Strategic Logistics Management",
      "Logistics Analytics",
      "Business Finance",
    ],
  },
  {
    title: "Master of Development Studies",
    degrees: [
      "Principles of Microeconomics",
      "Principles of Macroeconomics",
      "Evolution of Development Thinking",
      "Political Economy",
      "Money, Banking and Finance",
      "ASEAN Economies and Regional Integration",
      "Agricultural Transformation and Rural Development",
      "International Trade",
      "Research Methodology",
      "Education and Human Resource Development",
      "SMEs Development and Industrialization",
      "Environmental and Natural Resource Economics",
      "Public Policy and Administration",
      "Regional and Urban Development",
      "Infrastructure and Development",
      "Globalization and Development",
      "General Management",
    ],
  },
];
const headInfo = {
  name: "Dr. Sandar",
  profileImage: sandar,
  department: "Department of Economics",
  position: " Professor and Head of the Department of Economics",
  passage:
    "Lorem ipsum dolor sit amet consectetur. Turpis quam imperdiet nunc scelerisque. Quisque pellentesque faucibus in urna a mi amet. Augue dui lacus aliquam posuere varius nisi nulla sit. Eget diam ut ullamcorper vulputate at lobortis pellentesque hendrerit quis. Nibh pulvinar aliquet porttitor arcu erat lobortis. Tempor orci ac non rhoncus massa amet in felis fringilla. Feugiat justo mauris bibendum morbi neque gravida ornare odio est. Non mi eget tristique proin quam ullamcorper ac. Quam morbi eu non sapien urna nibh.",
};
const members = [
  [
    {
      name: "Dr. Sandar",
      profile: sandar,
      department: "Department of Economics",
      position: " Professor ",
    },
  ],
  // Group 1: Associate Professors
  [
    {
      name: "U Ko Myat",
      position: "Associate Professor",

      profile: uKoMyat,
    },
    {
      name: "Daw Thazin Htwe",
      position: "Associate Professor",

      profile: thazinHtwe,
    },
    {
      name: "Daw Ei Ei Zaw",
      position: "Associate Professor",
      degree: "A",
      profile: eiEiZaw,
    },
  ],
  // Group 2: Assistant Lecturers
  [
    {
      name: "U Yaw Zaw Lain",
      position: "Assistant Lecturer",

      profile: yawZawLain,
    },
    {
      name: "U Nyunt Lwin",
      position: "Assistant Lecturer",
      degree: "AL",
      profile: nyuntLwin,
    },
  ],
  // Group 3: Tutors
  [
    {
      name: "Daw Htet Yee Mon Oo",
      position: "Tutor",

      profile: htetYeeMonOo,
    },
    {
      name: "Daw Phyo Thinzar",
      position: "Tutor",

      profile: phyoThinzar,
    },
    {
      name: "Daw Ei Ei Win",
      position: "Tutor",

      profile: eieiWin,
    },
    {
      name: "U Khant Nyein Oo",
      position: "Tutor",

      profile: uKhantNyeinOo,
    },
    {
      name: "U Sai Phyo Naing Oo",
      position: "Part-time Tutor",

      profile: saiPhyonaing,
    },
  ],
];

const degrees = [
  "B. Econ (Economics)",
  "B. Econ (Economics) (Honours)",
  "M. Econ (Economics)",
  "PhD (Economics)",
];
const programs = ["MPA (Pioneer in Myanmar)"];
const facultyResearch = [
  {
    name: "Dr. Sandar (Professor/ Head)",
    research: [
      "Tourism Development in Bagan Cultural Heritage Sites, Yangon University of Economics Research Journal, Vol.6, No.1, Page 237-250, (2019)",
      "Residents’ Perception of Tourism Impacts in Bagan, The Myanmar Journal, Vol.7, No.4, Page 138-155, (2020)",
      "A study on the Effects of Tourism on Cultural Heritage Sites in Bagan: A Qualitative Approach, Yangon University of Economics Research Journal, Vol.8, No.1, Page 120-134, (2021)",
      "The Growth of International Tourist Arrivals in Myanmar, The Myanmar Journal, Vol.9, No.1, Page 157-172, (2022)",
      "Socio-Economic Conditions of Sittwe Township, Sitway University Research Journal, Vol.8, No.1, Page 138-146, (2022)",
      "Impacts of Tourism Development on Cultural Heritage Conservation in Bagan, Monywa University of Economics Research Journal, Vol.13, Page 170-184, (2023)",
      "The Production of Gold in Sagaing Region, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, Vol.14, No.1, Page 44-58, (2024)",
      "An Analysis of Mobile Phone Services on Learning Society, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, Vol.14, No.1, Page 167-181, (2024)",
    ],
  },
  {
    name: "U Ko Myat (Associate Professor)",
    research: [
      "Knowledge and Practices of Soil and Water Conservation of Farmers in Monywa Township, Monywa University of Economics Research Journal, Vol.11, No.3, Page 175-191, (2020)",
      "Assessment on Life-Skill Based Comprehensive Sexuality Education (CSE) and Reproductive Health (SRH) among Youth (Case Study: Monywa University of Economics), Monywa University of Economics Research Journal, Vol.11, No.1, Page 193-212, (2020)",
      "Farmer’s Perception and Adaptation GAP in Vegetable Cultivation of Selected Villages in Sagaing Region, Monywa University of Economics Research Journal, Vol.11, No.1, Page 109-121, (2020)",
    ],
  },
  {
    name: "Dr. Daw Thazin Htwe (Associate Professor)",
    research: [
      "A Study on the Impact of North Nawin Dam and South Nawin Dam upon the Agricultural Development of Pyay District, Pyay University Research Journal, Vol.10, No.1, Page 121-129, (2018)",
      "Health and Health System in Myanmar, Pyay University Research Journal, Vol.10, No.1, Page 130-139, (2018)",
      "Foreign Direct Investment in Myanmar, Pyay University Research Journal, December Vol.11, No.2, Page 320-329, (2019)",
      "Development of Education in Myanmar, Pyay University Research Journal, December Vol.12, No.2, Page 320-329, (2020)",
      "A study of Trade Situation of Myanmar, Monywa University of Economics Research Journal, Vol.12, No.1, Page 123-138, (2022)",
      "The Opportunities and challenges of Vocational Training in Naypyitaw, Monywa University of Economics Research Journal, Vol.13, Page 52-66, (2023)",
    ],
  },
  {
    name: "Daw Ei Ei Zaw (Associate Professor)",
    research: [
      "မိတ္ထီလာမြို့နယ်စံပြကျေးရွာများ၏ လူမှုစီးပွားရေးဖွံ့ဖြိုးတိုးတက်မှုကို လေ့လာတင်ပြခြင်း, Meiktila University of Economics Research Journal, Vol.5, No.1, Page 193-264, (2014)",
      "The Customer Satisfaction of the Highway Service in Meiktila, Meiktila University of Economics Research Journal, Vol.7, No.1, Page 1-11, (2016)",
      "Financial Challenges Facing Township Development Affairs Organization in Myingyan Township, Meiktila University of Economics Research Journal, Vol.9, No.2, Page 79-94, (2019)",
      "A Study on Child Immunization in Meiktila Industrial Zone, Meiktila University of Economics Research Journal, Vol.10, No.3, Page 127-141, (2020)",
      "An Analysis of Mobile Phone Services on Learning Society, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, Vol.14, No.1, Page 167-181, (2024)",
    ],
  },
  {
    name: "U Nyunt Win (Assistant Lecturer)",
    research: [
      "A Study on Rural Health Care Service in Sagaing District, University of Co-operative and Management Research Journal, Vol.4, No.2, Page 92-105, (2021)",
      "Challenges of Women Living with HIV/AIDS in Socioeconomic Situation in Monywa Township, Monywa University of Economics International Research Journal, Vol.14, No.2, Page 202-214, (2024)",
    ],
  },
  {
    name: "Daw Ei Ei Win (Tutor)",
    research: [
      "A Study on Women’s Contribution of Home Based Handicraft Business in Palin Gone Village Tract, Monywa Township, Monywa University of Economics Research Journal, Vol.13, Page 202-217, (2023)",
    ],
  },
  {
    name: "Daw Phyo Thinzar (Tutor)",
    research: [
      "The Effects of Gold Mining Industries in Bhamo District, Monywa University of Economics Research Journal, Vol.13, Page 143-157, (2023)",
      "The Production of Gold in Sagaing Region, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, Vol.14, No.1, Page 44-58, (2024)",
    ],
  },
  {
    name: "Daw Htet Yee Mon Oo (Tutor)",
    research: [
      "An analysis of the Factors Influencing on Insurance Awareness, (2024)",
      "Challenges of Women Living with HIV/AIDS in Socioeconomic Situation in Monywa Township, Monywa University of Economics International Research Journal, Vol.14, No.2, Page 202-214, (2024)",
    ],
  },
];

const activities = [
  { title: "ဘောလုံး အားကစားပြိုင်ပွဲ", image: economicsActiFootball },
  { title: "ဘောလုံး အားကစားပြိုင်ပွဲ", image: economicsActiFootball2 },
  { title: "ဘောလီဘော အားကစားပြိုင်ပွဲ", image: economicsActiVolleyball },
  { title: "ကထိန်အလှူ", image: economicsActiWorship },
  { title: "ကထိန်အလှူ", image: economicsActiWorship2 },
];
const EconomicsDepartment = () => {
  return (
    <>
      <div
        style={{ padding: "0px 36px 30px 56px" }}
        className="mobile-margin-x-zero md:mx-32 ">
        <EconomicsHeading />
        <CommerceSectionsTemplate
          curriculumsTypes={curriculums}
          headMaster={headInfo}
          groupMembers={members}
          researches={facultyResearch}
          news={activities}
          offeredDegrees={degrees}
          hrdProgrammes={programs}
        />
      </div>
    </>
  );
};

export default EconomicsDepartment;
