import React from "react";
import Announcements25 from "../../components/announcements/Announcements25";

const AnnouncementsPage25 = () => {
  return <Announcements25 />;
  // return (
  //   <>
  //     <div className="min-h-96 text-9xl">Hello World</div>
  //   </>
};

export default AnnouncementsPage25;
