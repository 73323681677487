import { FaFacebookF, FaSearch } from "react-icons/fa";
import { Flex, Icon, Text, Image } from "../ui";
import { TfiWorld } from "react-icons/tfi";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom"; // Importing Link
import "./index.css";
import UniLogo from "../assets/departmentLogo/School loGo (2).jpg";
import { useEffect, useRef, useState } from "react";
import { Redirects } from "./Redirects";
import { IoMenu } from "react-icons/io5";

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);

  const [activeDropdown, setActiveDropDown] = useState(null);
  const [activeSubDropdown, setActiveSubDropDown] = useState(null);
  const [activeGrandchildrenDropdown, setActiveGrandchildrenDropDown] =
    useState(null);

  const dropdownRef = useRef(null);
  const handleSearchInput = () => {
    setIsSearchInputOpen(true);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropDown(null);
        setActiveSubDropDown(null);
        setActiveGrandchildrenDropDown(null);
        setMobileMenuOpen(false);
        setIsSearchInputOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMobileMenuOpen, setIsSearchInputOpen]);

  return (
    <>
      <Flex direction="col" className="fixed z-30">
        {isMobileMenuOpen ? (
          <div className="md:hidden overflow-y-scroll">
            <Redirects
              setActiveDropDown={setActiveDropDown}
              setActiveSubDropDown={setActiveSubDropDown}
              setActiveGrandchildrenDropDown={setActiveGrandchildrenDropDown}
              setMobileMenuOpen={setMobileMenuOpen}
              setIsSearchInputOpen={setIsSearchInputOpen}
              dropdownRef={dropdownRef}
              activeDropdown={activeDropdown}
              activeGrandchildrenDropdown={activeGrandchildrenDropdown}
              activeSubDropdown={activeSubDropdown}
            />
          </div>
        ) : (
          <Flex
            direction="row"
            justify="between"
            backgroundColor="#294A70"
            className="h-12 hidden md:flex min-w-[100vw]">
            <Flex
              direction="row"
              className="follow-us-section  -translate-x-20 lg:scale-90 md:scale-75 relative"
              backgroundColor="rgba(255, 215, 0, 1)">
              <Text
                size="16px"
                color="#333"
                backgroundColor="transparent"
                weight="600"
                content="Follow Us :"
              />
              <Icon
                IconComponent={FaFacebookF}
                size="25px"
                color="black"
                className="ml-10 bg-transparent"
              />
              <Icon
                IconComponent={TfiWorld}
                size="25px"
                color="black"
                className="ml-3 bg-transparent"
              />
            </Flex>

            {/* Contact Section */}
            <Flex
              direction="row"
              justify="end"
              align="center"
              backgroundColor="#294A70"
              className="contact-section  lg:scale-90 md:scale-75 h-full gap-4 pr-36">
              <div className="flex flex-row justify-start items-center">
                <div className="w-16 h-[53px] flex justify-center items-center bg-white">
                  <Icon
                    IconComponent={FaPhoneVolume}
                    size="25px"
                    color="#294A70" // change color to black or another visible color
                    className="bg-transparent rotate-icon"
                  />
                </div>
                <Text
                  size="14px"
                  color="white"
                  backgroundColor="transparent"
                  content="+959 757560883"
                  className="ml-5"
                />
              </div>
              <div className="flex flex-row justify-start items-center">
                <div
                  className="w-16 h-[52.5px] flex justify-center items-center bg-white"
                  style={{
                    marginTop: "1px",
                  }}>
                  <Icon
                    IconComponent={IoIosMail}
                    size="35px"
                    color="#294A70"
                    className="bg-transparent rotate-icon"
                  />
                </div>
                <Text
                  size="14px"
                  color="white"
                  backgroundColor="transparent"
                  content="muerectoroffice@gmail.com"
                  className="ml-5"
                />
              </div>
            </Flex>
          </Flex>
        )}

        <Flex
          direction="row"
          justify="between"
          className="bg-white pt-1 px-4 md:px-32 w-full">
          <Flex direction="row" justify="start" className="gap-3 mobile-row">
            {" "}
            <IoMenu
              size="35px"
              color="rgba(0, 51, 102, 1)"
              className="bg-transparent md:hidden"
              onClick={toggleMobileMenu}
            />
            <Link to="/">
              {" "}
              <Flex
                direction="row"
                justify="start"
                className="gap-3 mobile-row">
                <Image src={UniLogo} alt="Logo" width="auto" height="74px" />
                <p
                  style={{ color: "#294A70" }}
                  className=" font-medium text-xs md:text-base lg:text-2xl xl:text-3xl  whitespace-nowrap ">
                  Monywa University of Economics
                </p>
              </Flex>
            </Link>
          </Flex>
          <Flex
            backgroundColor={`${
              isSearchInputOpen ? "white" : "rgba(255,215,0,1)"
            }`}
            direction={`${isSearchInputOpen ? "rowReverse" : "row"}`}
            className="max-w-fit rounded-full pl-3 pt-2 pb-2 pr-1 md:max-w-80 md:rounded-3xl border-2 border-yellow-400  md:mr-4 h-10  gap-4">
            <Icon
              size="18px"
              IconComponent={FaSearch}
              onClick={() => {
                setIsSearchInputOpen(true);
              }}
              color={`${isSearchInputOpen ? "black" : "white"}`}
              className="font-extralight "
            />
            <input
              type="text"
              placeholder="Search"
              onClick={handleSearchInput}
              style={{
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
                marginLeft: "2px",
                marginRight: "-13px",
                border: "none",
              }}
              className={`w-[260px] search-input h-[35px] focus:border-none rounded-r-3xl pl-5${
                isSearchInputOpen ? "" : " mobile-hidden "
              }`}
            />
          </Flex>
        </Flex>

        <div
          className="w-full  mobile-hidden  "
          style={{ backgroundColor: "rgba(41,74,112,1)" }}>
          <Redirects
            setActiveDropDown={setActiveDropDown}
            setActiveSubDropDown={setActiveSubDropDown}
            setActiveGrandchildrenDropDown={setActiveGrandchildrenDropDown}
            setMobileMenuOpen={setMobileMenuOpen}
            setIsSearchInputOpen={setIsSearchInputOpen}
            dropdownRef={dropdownRef}
            activeDropdown={activeDropdown}
            activeGrandchildrenDropdown={activeGrandchildrenDropdown}
            activeSubDropdown={activeSubDropdown}
          />
        </div>
      </Flex>
    </>
  );
}

export default Navbar;
