import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  anno251,
  anno252,
  anno253,
  anno254,
  anno255,
  anno256,
} from "../../assets";
import { IoChevronBack } from "react-icons/io5";

const AnnouncementDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Destructure id from useParams
  const announcements = [
    {
      title:
        "ပြည်ထောင်စုစာရင်းစစ်ချုပ်ရုံး လက်ထောက်စာရင်းစစ်မှူး လျှောက်လွှာခေါ်ယူခြင်း",
      images: [anno254, anno255, anno256],
    },
    {
      title:
        "ပြည်ထောင်စုစာရင်းစစ်ချုပ်ရုံး ဒု-စာရင်းစစ်မှူး လျှောက်လွှာခေါ်ယူခြင်း ",
      images: [anno251, anno252, anno253],
    },
  ];

  const index = parseInt(id, 10); // Convert id to number
  console.log(`Parsed ID: ${index}`);
  const displayItem = announcements[index - 1]; // Use index to access the array

  if (!displayItem) {
    return <div className="min-h-96 text-3xl mt-5">Announcement not found</div>;
  }

  return (
    <div className="mt-5">
      <button
        className="flex items-center md:ml-32"
        onClick={() => navigate(-1)}>
        <IoChevronBack />
        Back
      </button>
      {/* <h1 className="text-3xl ">{displayItem.title}</h1> */}
      <div className="flex flex-col items-center justify-center">
        {displayItem.images.map((image, index) => (
          <div
            className="w-full max-w-[70vw]   bg-no-repeat min-h-[100vw] bg-contain"
            key={index}
            style={{ backgroundImage: `url(${image})` }}>
            {/* <img src={image} alt="idk" className="opacity-5 hidden w-full h-full" /> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnnouncementDetailPage;
